import React, { useState } from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth } from "../../../firebase";
import InputControl from "../../../components/inputController";

const Signup = () => {
  const navigate = useNavigate();

  const [value, setValue] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [errormsg, setErrormsg] = useState("");

  const handlesubmission = () => {
    if (!value.name || !value.email || !value.password) {
      setErrormsg("Fill in all fields");
    } else {
      createUserWithEmailAndPassword(auth, value.email, value.password)
        .then((res) => {
          // console.log(res);
          const user = res.user;

          updateProfile(user, {
            displayName: value.name,
          });

          setValue({
            name: "",
            email: "",
            password: "",
          });

          navigate("/");
        })
        .catch((error) => {
          // console.log('Error creating user:', error.message);
          setErrormsg(error.message);

          if (error.code === "auth/email-already-in-use") {
            // User is already registered, redirect to login page
            // navigate('/login');
            setErrormsg(error.message);
          }
        });
    }
  };

  return (
    <div className="signup_container">
      <div className="signup_innerBox">
        <div className="back">
          <button
            className="btn back_btn"
            onClick={() => {
              navigate("/");
            }}
          >
            <i className="fa fa-angle-double-left"></i> Back
          </button>
        </div>
        <h1 className="signup_heading">Sign Up</h1>
        <InputControl
          type="text"
          label="Name:"
          placeholder="Enter your Name"
          onChange={(e) =>
            setValue((prev) => ({ ...prev, name: e.target.value }))
          }
          value={value.name}
        />
        <InputControl
          type="email"
          label="Email:"
          placeholder="Enter email"
          onChange={(e) =>
            setValue((prev) => ({ ...prev, email: e.target.value }))
          }
          value={value.email}
        />
        <InputControl
          type="password"
          label="Password:"
          placeholder="Enter Password"
          onChange={(e) =>
            setValue((prev) => ({ ...prev, password: e.target.value }))
          }
          value={value.password}
        />

        <div className="signup_footer">
          <b className="signup_error">{errormsg}</b>
          <button onClick={handlesubmission}>Sign Up</button>
          <p className="signup_p">
            Already have an acccount?{" "}
            <span>
              <Link to="/Login">Login</Link>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
