import { useState, useEffect } from "react";
import { auth } from "../../../firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Prompts = ({ domainData }) => {
  const [user, setUser] = useState(null);

  const [data, setData] = useState({
    topic: "",
    filename: "",
  });

  const [showWarning, setShowWarning] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleUploadClick = () => {
    if (!data.topic || !data.filename) {
      setShowWarning(true);
      return;
    }

    setIsSubmitting(true);

    fetch(
      "https://us-central1-kreate-stripe-api.cloudfunctions.net/api/kreate/upload",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then(async (result) => {
        setIsSubmitting(false);
        setShowWarning(false);
        if (!result.ok) {
          throw new Error("API call failed");
        }
        console.log(result.url);
        toast.success("File uploaded successfully");
      })
      .catch((error) => {
        setIsSubmitting(false);
        console.error("Error fetching data");
        toast.error("Error uploading file");
      });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <div className="container-fluid py-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center py-5">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <header className="major">
                <h2>
                  <span id="ctl00_ContentPlaceHolder1_title">
                    Use prompt to create pages
                  </span>
                </h2>
              </header>
              <br />

              <div className="col justify-content-center mb-5">
                <label htmlFor="urlInput" className="h4 mb-4">
                  Add Prompt:
                </label>
                <textarea
                  type="text"
                  id="urlInput"
                  className="form-control"
                  rows="5"
                  value={data.topic}
                  placeholder="Enter your prompt here"
                  onChange={handleChange}
                  name="topic"
                ></textarea>
              </div>

              <div className="col justify-content-center">
                <label htmlFor="aliasInput" className="h4 mb-4">
                  Add prompt alias:
                </label>
                <input
                  type="text"
                  id="aliasInput"
                  className="form-control"
                  value={data.filename}
                  placeholder="Enter your bucket path here"
                  onChange={handleChange}
                  name="filename"
                />
              </div>
              <div className="row justify-content-center mt-2">
                {showWarning && (
                  <div className="col-md-6 text-danger">
                    Please fill out all fields
                  </div>
                )}
              </div>
              <div className="row justify-content-center mt-2">
                <button
                  type="button"
                  className="btn btn-primary w-25"
                  onClick={handleUploadClick}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Uploading..." : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Prompts;
