import { BrowserRouter, Route, Routes } from "react-router-dom";

import DomainContext from "./store/domain-context";
import Home from "./pages/Home";
import Signup from "./pages/auth/signUp";
import Login from "./pages/auth/login";
import ResetPasswordPage from "./pages/auth/forgotPassword";
import Contact from "./pages/contact";
import MyAccount from "./pages/myAccount";

import { useEffect, useState } from "react";
import { auth } from "./firebase";

function App() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      }
    });

    return async () => {
      unsubscribe();
    };
  }, []);

  const [globalDomainData, setGlobalDomainData] = useState([]);

  return (
    <DomainContext.Provider
      value={{
        globalDomainData,
        setGlobalDomainData,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/contact" element={<Contact />} />

          <Route
            path="/my-account"
            element={user ? <MyAccount /> : <Login />}
          />
          <Route path="forgot_password" element={<ResetPasswordPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      </BrowserRouter>
    </DomainContext.Provider>
  );
}

export default App;
