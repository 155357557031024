// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_apiKey,
//   authDomain: process.env.REACT_APP_authDomain,
//   projectId: process.env.REACT_APP_projectId,
//   storageBucket: process.env.REACT_APP_storageBucket,
//   messagingSenderId: process.env.REACT_APP_messagingSenderId,
//   appId: process.env.REACT_APP_appId,
//   measurementId: process.env.REACT_APP_measurementId,
// };

const firebaseConfig = {
  apiKey: "AIzaSyACU3aPjBaQHgIk1M7O457YteYN5mYW27g",
  authDomain: "kreatewebsite-new-template.firebaseapp.com",
  projectId: "kreatewebsite-new-template",
  storageBucket: "kreatewebsite-new-template.appspot.com",
  messagingSenderId: "988959267955",
  appId: "1:988959267955:web:abaa1ec3024f81c78d9e06",
  measurementId: "G-ET064B0ZTC",
  databaseURL: "https://kreatewebsite-new-template-default-rtdb.firebaseio.com",
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
export const database = getDatabase(firebaseApp);
const auth = firebase.auth();

export const db = getFirestore(firebaseApp);

export { auth };
